<template>
 <div class="bg-img" style="background: url('/assets/img/seraa/n-bg.jpg') no-repeat center center fixed;">
    <v-container class="seraa_con">
        <div class="slider">
            <div :class="'sbtn lbtn2 d-none d-sm-flex '+ $store.state.direction"></div>
            <swiper :modules="modules" navigation class="swiper dirr" ref="mySwiper5" :options="swiperOptions5">
                <swiper-slide v-for="(i,n) in seraares" :key="i">
                    <v-btn class="mx-2" :input-value="n" :class="{ 'greeen white--text':active==n}" @click="showMore(i.id, n)">
                        {{i.title}}
                    </v-btn>
                </swiper-slide>
            </swiper>
            <div :class="'sbtn rbtn2 d-none d-sm-flex '+ $store.state.direction"></div>
        </div>
        <div>
            <div class="d-flex co-div">
                <span class="z-size mx-2"></span>
                <h2 class="tl">{{this.moreres[0].title}}</h2>
            </div>
            <p class="mx-3 home_main_new_d tx" v-html="this.moreres[0].caption"></p>
        </div>
    </v-container>
</div>
</template>

<script>

export default {
    name: "Seraa",
    data() {
        return {
            seraares : [],
            moreres: [{
                id: '',
                title: '',
                caption: '',
            }],
            active: 0,
            swiperOptions5: {
                slidesPerView: 'auto',
                spaceBetween: 15,
                keyboard: true,
                //loop: true,
                navigation: {
                    nextEl: ".rbtn2",
                    prevEl: ".lbtn2",
                },
            },
            //modules: [Navigation],
        }
    },
 

    computed: {
        swiper() {return this.$refs.mySwiper5.$swiper}
    },
    mounted() {  
    document.title =this.$t("index_abbas");  
  }, 
    created () {
        this.getSeraa ();
    },
    methods:{
        getSeraa () {
            this.$axios.get("abbas-histories/getByCatid/1", {
            })
            .then(res => {
                // this.seraares = res.data;
                const uniqueElementsBy = (arr, fn) => arr.reduce((acc, v) => { if (!acc.some(x => fn(v, x))) acc.push(v); return acc; }, []);
                this.seraares= uniqueElementsBy(res.data,(a, b) => a.id == b.id);
                this.showMore (this.seraares[0].id, 0);
            })
            .catch(() => {
            });
        },
        showMore (x, n) {
            this.active = n;
            this.$axios.get("abbas-histories/getById/"+x, {
            })
            .then(res => {
                this.moreres = res.data;
            })
            .catch(() => {
            });
        },
    },
}
</script>
<style lang="css">
    .bg-img {
       /* background: url('https://alkafeel.net/alkafeel_back_test/img/misc/pray_bkg_2.jpg') no-repeat center center fixed; */
       background-size: cover !important;
       
       
    }
    .greeen {
        background-color: #a0b435 !important;
        border-color: #a0b435 !important;
    }
    .z-size {
        height: 18px;
        width: 18px;
        background-size:cover ;
        /*background-image: url('http://localhost:8082/assets/img/z-mohammed.svg');*/
    }
    .co-div {
        align-items: center;
    }
    .home_main_new_d {
    /* font-family: bbc_nassim,Arial,sans-serif; */
    font-size: 19px;
    line-height: 31.26px;
    color: #727171;
    padding: 20px;
    border-radius: 4px;
    background: #ffffffde;
    font-weight: bold;
    text-align: justify;
}

    .v-btn__content {
        letter-spacing: 0 !important;
        font-family: cairo_bold,Arial,sans-serif !important;
    }
    
</style>
<style scoped>
    /* style effects header comp. 🙄*/
    .theme--light.v-sheet{
        background: #fff0 !important;
    }
</style>